const BASE_URL = "https://radrunapi.appdeft.in/api/admin";
// const BASE_URL = "http://localhost:1109/api/admin";

const HOST = "https://radrunapi.appdeft.in/";
const URL = "https://radrunapi.appdeft.in/";

// const HOST = "http://localhost:1109/";
// const URL = "http://localhost:1109/";

export { BASE_URL, HOST, URL };
