import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect } from "react";
import TableCard from "../../shared/TableCard/TableCard";
import { useHistory } from "react-router-dom";
import { confirmPopup } from "primereact/confirmpopup";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { deleteAmenities } from "../../redux/actions/UsersAction";
import { getEvents, updateEvent } from "../../redux/actions/eventAction";
import moment from "moment";
import { URL } from "../../constants";

export const EventsTable = () => {
    let history = useHistory();
    let dispatch = useDispatch();
    const allEvents = useSelector((state) => state.allEvents.allEvents);

    const deleteRow = (e, id) => {
        confirmPopup({
            target: e.currentTarget,
            message: "Are you sure you want to delete?",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
                dispatch(updateEvent({ isDeleted: true }, id));
            },
            reject: () => {},
        });
    };
    useEffect(() => {
        dispatch(getEvents());
    }, []);

    const actions = (data) => {
        return (
            <div className="flex justify-content-around">
                <a target="_blank" href={`https://rad-run.appdeft.in/#/event_info/${data._id}`}>
                    <i className="pi pi-eye cursor-pointer"></i>
                </a>
                <i className="pi pi-pencil cursor-pointer mx-2" onClick={() => history.push(`/event/edit/${data._id}`)}></i>
                <i className="pi pi-trash cursor-pointer" onClick={(e) => deleteRow(e, data._id)}></i>
            </div>
        );
    };

    const eventScheduleTemplate = (e) => {
        if (moment(moment(e?.date).format("DD/MM/YYYY")).isSame(moment().format("DD/MM/YYYY"))) return <span className="text-green-300">Today</span>;
        else if (moment(e?.date).isBefore()) return <span className="text-pink-300">Past</span>;
        else return <span className="text-yellow-300">Upcoming</span>;
    };
    return (
        <>
            <TableCard title="Events" buttonTitle="Add Event" linkTo={`/event/add`}>
                <DataTable paginator value={allEvents} showGridlines rows={10} emptyMessage="No event requests found.">
                    <Column
                        field="image"
                        body={(e) => (
                            <>
                                <img src={URL + e?.image} alt="" width={100} />
                            </>
                        )}
                        sortable
                        header="Image"
                    ></Column>
                    <Column
                        field="eventOrganizer"
                        body={(e) => e.eventOrganizer?.firstName + " " + e.eventOrganizer?.lastName}
                        sortable
                        header="Event Organizer"
                    ></Column>
                    <Column field="name" sortable header="Event Name"></Column>
                    <Column
                        field="address"
                        body={(e) => (typeof e?.addres === "string" ? JSON.parse(e?.address).name : e?.address.name)}
                        sortable
                        header="Address"
                    ></Column>
                    <Column field="date" body={(e) => moment(e?.date).format("DD/MM/YYYY")} sortable header="Date"></Column>
                    <Column field="time" body={(e) => moment(e?.time).format("hh:mm")} sortable header="Time"></Column>
                    <Column field="loopDistance" sortable header="Loop Distance"></Column>
                    <Column field="date" body={eventScheduleTemplate} sortable header="Status"></Column>
                    <Column body={actions} style={{ width: "80px" }} header="Action"></Column>
                </DataTable>
            </TableCard>
        </>
    );
};
