export const types = {
    SHOW_TOAST: "SHOW_TOAST",
    SHOW_LOADER: "SHOW_LOADER",
    HIDE_LOADER: "HIDE_LOADER",
    SHOW_SIDEBAR_LOADER: "SHOW_SIDEBAR_LOADER",
    HIDE_SIDEBAR_LOADER: "HIDE_SIDEBAR_LOADER",
    USER_DETAIL: "USER_DETAIL",
    USERS: "USERS",
    REQUEST_EVENTS: "Request_Event",
    EVENTS: "Events",
    EVENT: "EVENT"
};
