import { combineReducers } from "redux";
import loaderReducer from "./loaderReducer";
import profileReducer from "./profileReducer";
import toastReducer from "./toastReducer";
import uploadReducer from "./uploadReducer";
import hostReducer from "./hostReducer";
import UsersReducer from "./UsersReducer";
import EventsReducer from "./EventReducer";

export default combineReducers({
    toast: toastReducer,
    loader: loaderReducer,
    profile: profileReducer,
    upload: uploadReducer,
    allUsers: UsersReducer,
    allEvents: EventsReducer,
    hosts: hostReducer,
});
