import React, { useState, useEffect } from "react";
import { isAuthenticated } from "../services/auth";
import { Link } from "react-router-dom";
import logo from "../assets/images/Logo.jpeg";
import { useDispatch } from "react-redux";
import { loginAction } from "../redux/actions/loginAction";
import { showFormErrors } from "../utils/commonFunctions";
import { allValidations } from "../utils/formValidations";
import { CustomForm, CustomInput, CustomPassword } from "../shared/Input/AllInputs";
import PrimaryButton from "../shared/Button/PrimaryButton";
import CustomCard from "../shared/Card/CustomCard";
import Background from "../shared/Background/Background";
import { useHistory } from "react-router-dom";

const Login = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [form, setForm] = useState({
        email: "",
        password: "",
    });

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.documentElement.style.fontSize = 14 + "px";
        if (isAuthenticated()) {
            history.push("/dashboard");
        }
    }, [history]);

    const handleChange = ({ name, value }) => {
        const formErrors = allValidations(name, value, form);
        setForm((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (showFormErrors(form, setForm)) {
            dispatch(loginAction(form, setLoading, history));
        }
    };

    return (
        <Background>
            <div className="grid mt-5 border-round-lg">
                <div className="col-11 md:col-6 mx-auto border-round-lg">
                    <CustomCard className="bg-white" backable={false}>
                        <div className="text-center ">
                            <img src={logo} alt="logo" style={{ width: "100px" }} />
                        </div>
                        <h2>Login</h2>
                        <form onSubmit={handleSubmit}>
                            <CustomInput data={form} onChange={handleChange} col="12" name="email" label="Email" />
                            <CustomPassword data={form} onChange={handleChange} col="12" name="password" label="Password" />
                            <PrimaryButton label="Login" type="submit" onClick={handleSubmit} loading={loading} />
                        </form>
                        <div className="text-right mt-4">
                            <Link to="/forgetpassword" className="text-decoration-none">
                                &nbsp;
                                <span className="h6 text-900 font_bolder text-right">Forgot Password?</span>
                            </Link>
                        </div>
                    </CustomCard>
                </div>
            </div>
        </Background>
    );
};
export default Login;
