import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function CustomCard({ title, children, className, backable = true, ...props }) {
    const history = useHistory();
    return (
        <div className={`label-table ${className}`}>
            {backable ? <i className="pi pi-angle-left text-4xl my-auto cursor-pointer " onClick={() => history.goBack()} /> : null}
            <div className="text-2xl my-auto">{title}</div>

            <div className="p-3  border border-1 border-300 p-fluid">{children}</div>
        </div>
    );
}
