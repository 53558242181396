import api from "../../services/api";
import Constants from "../../services/constant";
import { hideLoaderAction, showLoaderAction } from "./loaderAction";
import { types } from "../types/types";
import { showToast } from "./toastAction";

const getEvents = () => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.GET_EVENTS);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.EVENTS,
                payload: res.data,
            });
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

const getEventById = (id, setData) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.GET_EVENTS + id);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.EVENT,
                payload: res.data,
            });
            setData({
                ...res.data,
                date: new Date(res.data.date),
                time: new Date(res.data.time),
                eventOrganizer: res.data.eventOrganizer._id,
            });
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

const GetOrganizers = (setOrganizers) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.GET_ORGANIZERS);
    if (res.success) {
        if (res.data) {
            setOrganizers(res.data);
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

const getRequestEvents = (setRequestEvent) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.GET_REQUEST_EVENTS);
    if (res.success) {
        if (res.data) {
            setRequestEvent(res.data);
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};
const createEvent = (data, history) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("post", Constants.END_POINT.GET_EVENTS, data);
    if (res.success) {
        if (res.data) {
            history.push("/events");
            dispatch(showToast({ severity: "success", summary: res.message }));
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};
const updateEvent = (data, id, history) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("put", Constants.END_POINT.GET_EVENTS + id, data);
    if (res.success) {
        if (res.data) {
            history.push("/events");
            dispatch(showToast({ severity: "success", summary: res.message }));
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};
export { getEvents, getRequestEvents, createEvent, updateEvent, GetOrganizers, getEventById };
