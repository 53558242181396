import React from "react";
import CustomCard from "../../shared/Card/CustomCard";
import EventForm from "./EventForm";

export default function EditEvent() {
    return (
        <CustomCard>
            <EventForm type="EDIT" />
        </CustomCard>
    );
}
