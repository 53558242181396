import React from "react";
import CustomCard from "../../shared/Card/CustomCard";
import EventForm from "./EventForm";

export default function AddEvent() {
    return (
        <CustomCard>
            <EventForm type="ADD" />
        </CustomCard>
    );
}
