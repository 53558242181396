import React, { useEffect, useRef, useState } from "react";
import { CustomCalenderInput, CustomDropDown, CustomEditor, CustomForm, CustomInput, CustomInputNumber } from "../../shared/Input/AllInputs";
import PrimaryButton from "../../shared/Button/PrimaryButton";
import { allValidations } from "../../utils/formValidations";
import CustomUploadFile from "../../components/CustomUploadFile";
import { uploadImages } from "../../utils/uploadFiles";
import { ConvertIntoFromData, showFormErrors } from "../../utils/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { createEvent, getEventById, updateEvent } from "../../redux/actions/eventAction";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import InputLayout from "../../shared/Input/InputLayout";
import Constants from "../../services/constant";
import { HOST } from "../../constants";
import { getUsers } from "../../redux/actions/UsersAction";
import { RecursionOptions, RecursionPeriodOptions } from "../../utils/constant";

const EventForm = ({ type }) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const history = useHistory();
    const [ImageFile, setImageFile] = useState();
    const inputRef = useRef();
    const event = useSelector((state) => state.allEvents.event);
    const allUsers = useSelector((state) => state.allUsers.allUsers);

    const [data, setData] = useState({
        eventOrganizer: "",
        image: "",
        name: "",
        recursion: "",
        recursionPeriod: "",
        address: "",
        city: "",
        postCode: "",
        date: "",
        time: "",
        loopDistance: 200,
        description: "",
        state: "",
        country: "",
    });

    // google location
    const autoComplete = new window.google.maps.places.Autocomplete(inputRef.current);

    const handleChange = ({ name, value }) => {
        if (type !== "VIEW") {
            const formErrors = allValidations(name, value, data);
            setData((prev) => ({ ...prev, [name]: value, formErrors }));
        }
    };

    const handleEditor = (name, value) => {
        const formErrors = allValidations(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    useEffect(() => {
        dispatch(getUsers());
    }, [dispatch]);

    useEffect(() => {
        const formErrors = allValidations("image", ImageFile, data);
        setData({ ...data, image: ImageFile, formErrors });
    }, [ImageFile]);

    useEffect(() => {
        if (id) {
            dispatch(getEventById(id, setData));
        }
    }, [id]);

    autoComplete.addListener("place_changed", () => {
        const place = autoComplete.getPlace();
        const formErrors = allValidations("address", place?.formatted_address, data);
        if (place) {
            const splitAddress = place.formatted_address.split(",");
            setData({
                ...data,
                address: {
                    name: place.formatted_address,
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                },
                city: splitAddress[0] ? splitAddress[0].trim() : place.formatted_address,
                state: splitAddress[1] ? splitAddress[1].trim() : place.formatted_address,
                country: splitAddress[2] ? splitAddress[2].trim() : place.formatted_address,
                formErrors,
            });
        }
    });

    const onSubmit = async () => {
        const ignore = data.recursion ? ["recursionPeriod"] : [];
        if (showFormErrors(data, setData, ignore)) {
            let newData = ConvertIntoFromData(data);
            if (type === "ADD") {
                dispatch(createEvent(newData, history));
            }
            if (type === "EDIT") {
                dispatch(updateEvent(newData, id, history));
            }
        }
    };

    return (
        <CustomForm style={type === "VIEW" ? { pointerEvents: "none" } : {}}>
            <div className="flex flex-column justify-content-center p-3">
                <InputLayout name="image" data={data}>
                    {id ? <img src={HOST + data.image} alt="" width={200} /> : null}
                    <CustomUploadFile name={"image"} ImageFile={ImageFile} setImageFile={setImageFile} uploadImage={uploadImages}></CustomUploadFile>
                </InputLayout>
                <div className="grid">
                    <CustomDropDown
                        label="User"
                        options={allUsers?.map((item) => {
                            return {
                                name: `${item?.firstName} ${item?.lastName} (${item?.email})`,
                                value: item?._id,
                            };
                        })}
                        value={data?.eventOrganizer}
                        name="eventOrganizer"
                        data={data}
                        onChange={handleChange}
                        col={12}
                    ></CustomDropDown>
                    <CustomInput label="Event Name" name="name" data={data} value={data?.name} onChange={handleChange} col={6} />
                    <CustomDropDown
                        label="Recusrion"
                        options={RecursionOptions}
                        value={data?.recursion}
                        name="recursion"
                        data={data}
                        onChange={handleChange}
                        optionValue="value"
                        col={6}
                    ></CustomDropDown>
                    {data.recursion === "RECURRING" ? (
                        <CustomDropDown
                            label="Recursion Period"
                            options={RecursionPeriodOptions}
                            value={data?.recursionPeriod}
                            name="recursionPeriod"
                            data={data}
                            onChange={handleChange}
                            optionValue="value"
                            col={6}
                        ></CustomDropDown>
                    ) : null}

                    <InputLayout col={6} label={"Address"} name={"address"} data={data}>
                        <input
                            placeholder="Type your location"
                            name="address"
                            ref={inputRef}
                            value={data.address.name}
                            onChange={(e) => handleChange({ name: e.target.name, value: e.target.value })}
                            className="w-full googlelocationstyle"
                        />
                    </InputLayout>
                    {/* <CustomGoogleLocation onPlaceSelected={(place) => handleGoogleLocation(place)}></CustomGoogleLocation> */}
                    <CustomInput label="City" name="city" value={data?.city} data={data} onChange={handleChange} col={6} />
                    <CustomInput label="Postcode" name="postCode" value={data?.postCode} data={data} onChange={handleChange} col={6} />
                    <CustomCalenderInput
                        label="Date"
                        name="date"
                        inputClass={"calendar-box"}
                        data={data}
                        value={data?.date}
                        minDate={new Date()}
                        onChange={handleChange}
                        col={6}
                    />
                    <CustomCalenderInput
                        label="Time"
                        name="time"
                        data={data}
                        value={data?.time}
                        onChange={handleChange}
                        col={6}
                        timeOnly
                        showIcon={false}
                    />
                    <CustomInputNumber
                        label="Loop distance (200m - 1000m)"
                        name="loopDistance"
                        data={data}
                        min={200}
                        max={1000}
                        onChange={handleChange}
                        col={12}
                    />
                    <CustomEditor
                        label="Description"
                        name="description"
                        data={data}
                        value={data?.description}
                        onTextChange={(e) => handleEditor("description", e.htmlValue)}
                        col={12}
                    />
                </div>
                <div className=" flex justify-content-end w-full">
                    <div className="col-2"> {type !== "VIEW" && <PrimaryButton label="Save" onClick={onSubmit} col={6} />} </div>
                </div>
            </div>
        </CustomForm>
    );
};

export default EventForm;
