const menuItems = () => {
    return [
        {
            items: [
                {
                    iconactive: "pi pi-home",
                    label: "Home",
                    to: "/dashboard",
                },

                {
                    iconactive: "pi pi-user",
                    label: "Users",
                    to: "/Users",
                },
                {
                    iconactive: "pi pi-users",
                    label: "Event Requests",
                    to: "/event-requests",
                },
                {
                    iconactive: "pi pi-users",
                    label: "Events",
                    to: "/events",
                },
            ],
        },
    ];
};

export { menuItems };
