import React from "react";
import CustomCard from "../../shared/Card/CustomCard";
import UserForm from "./UserForm";

export default function AddUser() {
    return (
        <CustomCard>
            <UserForm type="ADD" />
        </CustomCard>
    );
}
