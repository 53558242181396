import React from "react";
import { Button } from "primereact/button";

export default function PrimaryButton({
  type,
  onClick,
  label,
  loading,
  extraClassName,
  ...props
}) {
  return (
    <Button
      type={type}
      label={label}
      onClick={onClick}
      loading={loading}
      className={`mx-1 my-2 bg-main ${extraClassName}`}
      {...props}
    />
  );
}
export function PrimaryButtonOutlined({
  type,
  onClick,
  label,
  loading,
  extraClassName,
  ...props
}) {
  return (
    <Button
      type={type}
      label={label}
      onClick={onClick}
      loading={loading}
      className={`mx-1 p-button-outlined my-2 ${extraClassName}`}
      {...props}
    />
  );
}
