import api from "../../services/api";
import Constants from "../../services/constant";
import { hideLoaderAction, showLoaderAction } from "./loaderAction";
import { types } from "../types/types";
import { uploadFile } from "../../utils/uploadFiles";
import { showToast } from "./toastAction";

const createAmenities = (data, setLoading, history) => async (dispatch) => {
    dispatch(showLoaderAction());
    setLoading(true);

    let vendorType = data.vendorType._id;
    data.icon = await uploadFile(data.icon);

    const res = await api("post", Constants.END_POINT.AMENITIES, { ...data, vendorType });
    if (res.success) {
        history.push("/vendors/amenities");
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }

    setLoading(false);
    dispatch(hideLoaderAction());
};

const getUsers = () => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.USERS);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.USERS,
                payload: res.data,
            });
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

const amenitiesDetails = (id, returnData) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", `${Constants.END_POINT.AMENITIES}${id}`);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.AMENITIES_DETAILS,
                payload: res.data,
            });
            if (returnData) {
                returnData(res.data);
            }
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }

    dispatch(hideLoaderAction());
};

const updateAmenities = (id, data, setLoading, history) => async (dispatch) => {
    dispatch(showLoaderAction());
    setLoading(true);

    data.icon = await uploadFile(data.icon);

    const res = await api("put", `${Constants.END_POINT.AMENITIES}${id}`, data);
    if (res.success) {
        history.push("/vendors/amenities");
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    setLoading(false);
    dispatch(hideLoaderAction());
};

const deleteAmenities = (id) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("delete", `${Constants.END_POINT.AMENITIES}${id}`);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.DELETE_AMENITIES,
                payload: res.data,
            });
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

export { createAmenities, getUsers, amenitiesDetails, updateAmenities, deleteAmenities };
