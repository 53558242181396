import React, { useState } from "react";
import { CustomCalenderInput, CustomForm, CustomInput } from "../../shared/Input/AllInputs";
import PrimaryButton from "../../shared/Button/PrimaryButton";

import { allValidations } from "../../utils/formValidations";

import CustomUploadFile from "../../components/CustomUploadFile";
import { uploadImages } from "../../utils/uploadFiles";

const UserForm = ({ type }) => {
    const [ImageFile, setImageFile] = useState();
    const [data, setData] = useState({
        name: "",
        icon: "",
        vendorType: "",
        isActive: true,
    });

    // useEffect(() => {
    //     dispatch(getVendorCategories());

    //     if (id) {
    //         dispatch(
    //             amenitiesDetails(id, (data) => {
    //                 if (data.vendorType) {
    //                     let dataVendorType = allVendorCategories?.find((item) => item._id === data.vendorType);
    //                     data.vendorType = { _id: dataVendorType._id, name: dataVendorType.name };
    //                 }
    //                 data.icon = data.icon ? [data.icon] : "";
    //                 setData(data);
    //             })
    //         );
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [id, dispatch]);

    const [loading, setLoading] = useState(false);

    // const allVendorCategoriesList = allVendorCategories.map((item) => ({ _id: item._id, name: item.name }));

    const handleChange = ({ name, value }) => {
        if (type !== "VIEW") {
            const formErrors = allValidations(name, value, data);
            setData((prev) => ({ ...prev, [name]: value, formErrors }));
        }
    };

    const onSubmit = async () => {
        // if (showFormErrors(data, setData, ignoreFields)) {
        //     if (type === "ADD") {
        //         dispatch(createAmenities(data, setLoading, history));
        //     }
        //     if (type === "EDIT") {
        //         dispatch(updateAmenities(id, data, setLoading, history));
        //     }
        // }
    };

    return (
        <CustomForm style={type === "VIEW" ? { pointerEvents: "none" } : {}}>
            {" "}
            <div className="shadow-2 p-3   flex flex-column justify-content-center">
                <CustomUploadFile name ImageFile={ImageFile} setImageFile={setImageFile} uploadImage={uploadImages}></CustomUploadFile>
                <div className="grid">
                    <CustomInput label="Event Name" name="eventName" data={data} onChange={handleChange} col={6} />
                    <CustomInput label="Address" name="address" data={data} onChange={handleChange} col={6} />
                    <CustomInput label="City" name="city" data={data} onChange={handleChange} col={6} />
                    <CustomInput label="Postcode" name="postCode" data={data} onChange={handleChange} col={6} />
                    <CustomCalenderInput label="Postcode" name="postCode" data={data} onChange={handleChange} col={6} />
                    <CustomInput label="Time" name="time" data={data} onChange={handleChange} col={6} />
                    <CustomInput label="Loop distance (200 m-1 km)" name="loopDistance" data={data} onChange={handleChange} col={12} />
                </div>
                {/* <CustomDropDown label="Category" data={data} name="vendorType" onChange={handleChange} options={allVendorCategoriesList} col={12} /> */}
                {/* <CustomInputSwitch label="Active" name="isActive" onChange={handleChange} data={data} /> */}
                <div className=" flex jutify-content-end w-full">
                    <div className="col-2"> {type !== "VIEW" && <PrimaryButton label="Save" loading={loading} onClick={onSubmit} col={6} />} </div>
                </div>
            </div>
        </CustomForm>
    );
};

export default UserForm;
