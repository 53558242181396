export const LevelOptions = [
    {
        name: "Beginner",
        value: "Beginner",
    },
    {
        name: "Experienced",
        value: "Experienced",
    },
];

export const MeditationType = [
    {
        name: "Breathwork Meditation",
        value: "Breathwork Meditation",
    },
    {
        name: "Visualization Meditation",
        value: "Visualization Meditation",
    },
    {
        name: "Guided Meditation",
        value: "Guided Meditation",
    },
    {
        name: "Reiki Meditation",
        value: "Reiki Meditation",
    },
];

export const GenderOptions = [
    { name: "Male", value: "MALE" },
    { name: "Female", value: "FEMALE" },
    { name: "Other", value: "OTHER" },
    { name: "Nothing", value: "NOTHING" },
];

export const RecursionOptions = [
    { name: "One Time", value: "ONE_TIME" },
    { name: "Recurring", value: "RECURRING" }
];

export const RecursionPeriodOptions = [
    { name: "Weekly", value: "WEEKLY" },
    { name: "Monthly", value: "MONTHLY" },
    { name: "Yearly", value: "YEARLY" }
];