import React from "react";
import CustomCard from "../../shared/Card/CustomCard";
import UserForm from "./UserForm";

export default function EditUser() {
    return (
        <CustomCard>
            <UserForm type="EDIT" />
        </CustomCard>
    );
}
