import { types } from "../types/types";

const intitalState = {
    allUsers: [],
};

const UsersReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.USERS:
            return {
                ...state,
                allUsers: action.payload,
            };
        case types.AMENITIES_ADD:
            return {
                ...state,
                amenitiesCreate: action.payload,
            };
        case types.AMENITIES_DETAILS:
            return {
                ...state,
                amenitiesDetails: action.payload,
            };
        default:
            return { ...state };
    }
};

export default UsersReducer;
