import Dashboard from "../views/Dashboard/Dashboard";
import { EventRequestsTable } from "../views/Event-Requests/Events-RequestsTable";
import { EventsTable } from "../views/Events/EventsTable";
import AddEvent from "../views/Events/addEvent";
import AddUser from "../views/users/addUser";
import EditUser from "../views/users/EditUser";
import { UserTable } from "../views/users/UserTable";
import EditEvent from "../views/Events/editEvent";

export const AppRoutes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        exact: true,
    },

    {
        path: "/users",
        name: "User",
        component: UserTable,
        exact: true,
    },
    {
        path: "/users/add",
        name: "Add User",
        component: AddUser,
        exact: true,
    },
    {
        path: "/users/edit/:id",
        name: "Edit User",
        component: EditUser,
        exact: true,
    },
    {
        path: "/event-requests",
        name: "events",
        component: EventRequestsTable,
        exact: true,
    },
    {
        path: "/event/add",
        name: "events",
        component: AddEvent,
        exact: true,
    },
    {
        path: "/event/edit/:id",
        name: "events",
        component: EditEvent,
        exact: true,
    },
    {
        path: "/events",
        name: "events",
        component: EventsTable,
        exact: true,
    },
];
